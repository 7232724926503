module.exports = {
  eg: 'e.g.',
  mary: 'Mary',
  smith: 'Smith',
  marySmith: 'Mary Smith',
  marySmithEmailAddress: 'mary.smith@email.com',
  marySmithPhoneNumber: '+1 354 223 212 593',
  dobryGabinet: 'DobryGabinet',
  practiceStreet: 'Type in the street of your office',
  placeholderAll: 'All possibilities',
  city: 'Warsaw',
  zipCode: '00-123',
  taxNo: '987-654-32-10'
}
