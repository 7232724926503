module.exports = {
  choosePatients: '@:patients.selectPatient',
  addSelectedPatients: 'Dodaj wybranych pacjentów',
  editCoupleMembers: 'Edytuj członków',
  popups: {
    editName: 'Edytuj imię pary',
    coupleName: 'Imię pary',
    warningCaption1: 'Przynajmniej jeden pacjent musi pozostać w parze!',
    warningCaption2: 'Dodaj nowego pacjenta, aby usunąć/zmienić tego.',
    removeFromCouple: 'Usuń z pary',
    deletePatient: 'Usuń pacjenta',
    deletePatientTitle: 'Usunąć pacjenta?',
    deletePatientConfirmation:
      'Czy na pewno chcesz usunąć tego pacjenta? Cała historia pacjenta zostanie usunięta.',
    deletePatientWarning: 'Tego nie da się cofnąć!',
    removePatientTitle: 'Usuń pacjenta z pary',
    removePatientConfirmation:
      'Czy na pewno chcesz usunąć tego pacjenta z tej pary? '
  },
  menu: {
    editName: 'Edytuj imię',
    finishTherapy: 'Zakończ terapię',
    reactivateTherapy: 'Odnów terapię',
    deleteCouple: 'Usuń parę',
    confirmDelete: 'Czy chcesz usunąć tę parę?',
    confirmDeletePatient: 'Czy chcesz usunąć tego pacjenta?',
    finishTherapyDialog:
      'Czy na pewno chcesz zakończyć aktywną terapię dla tej pary??'
  },
  tabs: {
    meetingHistory: 'Historia spotkań',
    groupMembers: 'Członkowie pary',
    attachments: 'Załączniki',
    history: 'Historia',
    invoices: 'Faktury'
  },
  detailData: {
    addedOn: 'Para dodana dnia: ',
    meetingsHeld: '# odbytych spotkań: ',
    smsAll: 'SMS do wszystkich członków',
    emailAll: '@ Wyślij e-mail do wszystkich członków',
    therapists: 'Terapeuci',
    searchTherapists: 'Szukaj terapeuty',
    receiveNotify: 'Ta para otrzyma automatyczne powiadomienia',
    notReceiveNotify: 'Ta para NIE otrzyma automatycznych powiadomień',
    therapyFinishedOn: 'Terapia zakończona w dniu:'
  },
  finishTherapyDialog: {
    title: 'Zakończ terapię',
    caption1: 'Na pewno chcesz zakończyć terapię tej pary?',
    caption2:
      'Wszystkie dane zostaną zapisane, nadchodzące spotkania zostaną dezaktywowane (zaczynając od jutra).',
    caption2InMeeting:
      'Wszystkie dane zostaną zapisane, nadchodzące spotkania zostaną dezaktywowane (po tym spotkaniu).'
  },
  reactivateTherapyDialog: {
    title: 'Odnów terapię',
    caption1: 'Chcesz reaktywować terapię dla tej pary?',
    caption2:
      'Para będzie z powrotem dostępna przy tworzeniu spotkań oraz na liście par.'
  }
}
