module.exports = {
  COUPLE_CREATED: 'Couple was created',
  COUPLE_THERAPIST_ASSIGNED: 'Couple was assigned a therapist',
  COUPLE_ACTIVATED: 'Couple was activated',
  COUPLE_DEACTIVATED: 'Couple was deactivated',
  COUPLE_NOTES_ADDED: 'Meeting notes were added',
  COUPLE_NOTES_VIEWED: 'Meeting notes were viewed',
  COUPLE_THERAPY_FINISHED: "Couple's therapy was finished",
  COUPLE_THERAPY_REACTIVATED: "Couple's therapy was reactivated",
  COUPLE_NOTES_PRINTED: "Couple's notes were printed",

  GROUP_CREATED: 'Group was created',
  GROUP_THERAPIST_ASSIGNED: 'Group was assigned a therapist',
  GROUP_ACTIVATED: 'Group was activated',
  GROUP_DEACTIVATED: 'Group was deactivated',
  GROUP_NOTES_ADDED: 'Meeting notes were added',
  GROUP_NOTES_VIEWED: 'Meeting notes were viewed',
  GROUP_NOTES_PRINTED: "Group's notes were printed",

  PATIENT_CREATED: 'Patient was created',
  PATIENT_ACTIVATED: 'Patient was activated',
  PATIENT_DEACTIVATED: 'Patient was deactivated',
  PATIENT_THERAPIST_ASSIGNED: 'Patient was assigned a therapist',
  PATIENT_ASSIGNED_TO_GROUP: 'Patient was assigned to a group',
  PATIENT_ASSIGNED_TO_COUPLE: 'Patient was assigned to a couple',
  PATIENT_CHANGED_STATUS: 'Meeting status was changed',
  PATIENT_NOTES_ADDED: 'Meeting notes were added',
  PATIENT_NOTES_EDITED: 'Meeting notes were edited',
  PATIENT_NOTES_VIEWED: 'Meeting notes were viewed',
  PATIENT_NOTES_PRINTED: 'Meeting notes were printed',
  PATIENT_PSYCHOTHERAPY_NOTES_ADDED: 'Psychotherapy notes were added',
  PATIENT_SPEECH_THERAPY_NOTES_ADDED: 'Speech therapy notes were added',
  PATIENT_PSYCHOTHERAPY_NOTES_EDITED: 'Psychotherapy notes were edited',
  PATIENT_SPEECH_THERAPY_NOTES_EDITED: 'Speech therapy notes were edited',
  PATIENT_PSYCHOTHERAPY_NOTES_VIEWED: 'Psychotherapy notes were viewed',
  PATIENT_SPEECH_THERAPY_NOTES_VIEWED: 'Speech therapy notes were viewed',
  PATIENT_PSYCHOTHERAPY_NOTES_PRINTED: 'Psychotherapy notes were printed',
  PATIENT_SPEECH_THERAPY_NOTES_PRINTED: 'Speech therapy notes were printed',
  PATIENT_THERAPY_FINISHED: "Patient's therapy was finished",
  PATIENT_THERAPY_REACTIVATED: "Patient's therapy was reactivated",

  date: 'Date',
  action: 'Action',
  person: 'Person',
  therapist: 'Therapist',

  tooltips: {
    download: 'Click to download notes from this period',
    filter: 'Click to filter notes'
  },
  table: {
    empty: 'There is no actions in this period'
  }
}
