module.exports = {
  RODO: 'GDPR',
  yes: 'YES',
  no: 'NO',
  settings: 'Settings',
  automaticEmails:
    'When adding a new patient, the system will automatically send an email requesting consent for GDPR compliance',
  automaticEmailsDescription:
    "If you select the 'YES' option when creating a patient who has provided an email address, the system will automatically send an email with a link to the GDPR policy. Upon clicking the link, the patient will be redirected to the GDPR policy page, where they can provide their consent. The confirmation of the patient's consent can be checked in the patient panel.",
  automaticEmailsDescription2:
    "If you select the 'NO' option, you will be able to manually send consents to each patient. The sending options can be found in the patient's information section.",
  rodoPracticeBranch:
    'The consent sent for data processing will be customized for the clinic/practice',
  rodoPracticeBranchDescription:
    "Select the option that best describes your clinic/practice. If your center has multiple specialists, choose the option that is closest to your practice. If none of the options fit or your center is sufficiently interdisciplinary, select the 'Generic' option.",
  rodoPracticeBranchDescription2:
    'The consent includes information that informs patients that your clinic/center is the data controller, as well as the purposes of data processing. Additionally, it informs patients about their rights concerning their personal data.',
  tooltips: {
    signed: 'Signed:',
    notSigned: 'Not signed'
  },
  SPEECH_THERAPIST: 'Speechtherapist',
  PSYCHOTHERAPIST: 'Psychotherapist',
  GENERIC: 'Generic',
  OTHER: 'Other',
  adult: 'Adult',

  // child
  child: 'Child',
  SPEECH_THERAPIST_CHILD: 'Child - Speechtherapist',
  PSYCHOTHERAPIST_CHILD: 'Child - Psychotherapist',
  GENERIC_CHILD: 'Child - Generic',
  OTHER_CHILD: 'Child - Other',

  gdprPages: {
    adult: {
      footer: ', wyrażam zgodę na przetwarzanie moich danych osobowych przez',
      generic: {
        title1: 'Zgoda na przetwarzanie danych osobowych',
        title2: 'w związku z terapią lub konsultacją',
        p11: 'W związku z tym, że podczas terapii lub konsultacji będą zbierane informacje o szczególnym, prywatnym charakterze, proszę o wyrażenie zgody na przetwarzanie Pana/Pani danych osobowych',
        p12: 'Niewyrażenie zgody uniemożliwi prawidłowe przeprowadzenie terapii lub konsultacji. Wyrażona zgoda może być w każdej chwili cofnięta, jednak cofnięcie zgody nie wpłynie na zgodność z prawem przetwarzania, które miało miejsce przed jej wycofaniem.',
        p211: 'Administratorem Pan/Pani danych osobowych',
        p212: 'jest',
        p22: ', u którego dostępne są szczegółowe informacje na temat przetwarzania Pana/Pani danych osobowych.',
        p31: 'Jeżeli zgadza się Pan/Pani na przetwarzanie przez Administratora danych osobowych niezbędnych do przeprowadzenia terapii lub konsultacji, w tym danych dotyczących stanu zdrowia i innych okoliczności dotyczących Pana/Pani życia prywatnego',
        p32: 'mających znaczenie dla prowadzonej terapii lub konsultacji, proszę kliknąć poniżej.'
      },
      psychotherapist: {
        title1: '@:gdpr.gdprPages.adult.generic.title1',
        title2: 'w związku z psychoterapią lub konsultacją psychologiczną',
        p11: 'W związku z tym, że podczas psychoterapii lub konsultacji psychologicznej będą zbierane informacje o szczególnym, prywatnym charakterze, proszę o wyrażenie zgody na przetwarzanie Pana/Pani danych osobowych',
        p12: 'Niewyrażenie zgody uniemożliwi prawidłowe przeprowadzenie terapii lub konsultacji. Wyrażona zgoda może być w każdej chwili cofnięta, jednak cofnięcie zgody nie wpłynie na zgodność z prawem przetwarzania, które miało miejsce przed jej wycofaniem.',
        p211: '@:gdpr.gdprPages.adult.generic.p211',
        p212: '@:gdpr.gdprPages.adult.generic.p212',
        p22: '@:gdpr.gdprPages.adult.generic.p22',
        p31: 'Jeżeli zgadza się Pan/Pani na przetwarzanie przez Administratora danych osobowych niezbędnych do przeprowadzenia terapii, w tym danych dotyczących stanu zdrowia, życia rodzinnego i innych okoliczności dotyczących Pana/Pani życia prywatnego',
        p32: 'mających znaczenie dla prowadzonej terapii lub konsultacji, proszę kliknąć poniżej.'
      },
      speech: {
        title1: '@:gdpr.gdprPages.adult.generic.title1',
        title2: 'w związku z terapią lub konsultacją logopedyczną',
        p11: 'W związku z tym, że podczas terapii lub konsultacji logopedycznej będą zbierane informacje o szczególnym, prywatnym charakterze, proszę o wyrażenie zgody na przetwarzanie Pana/Pani danych osobowych',
        p12: 'Niewyrażenie zgody uniemożliwi prawidłowe przeprowadzenie terapii lub konsultacji. Wyrażona zgoda może być w każdej chwili cofnięta, jednak cofnięcie zgody nie wpłynie na zgodność z prawem przetwarzania, które miało miejsce przed jej wycofaniem.',
        p211: '@:gdpr.gdprPages.adult.generic.p211',
        p212: '@:gdpr.gdprPages.adult.generic.p212',
        p22: '@:gdpr.gdprPages.adult.generic.p22',
        p31: ' Jeżeli zgadza się Pan/Pani na przetwarzanie przez Administratora danych osobowych niezbędnych do przeprowadzenia terapii lub konsultacji logopedycznej, w tym danych dotyczących stanu zdrowia, życia rodzinnego, nagrań mowy, zdjęć (np. twarzy, jamy ustnej, zgryzu etc.) i innych okoliczności dotyczących Pana/Pani życia prywatnego',
        p32: 'mających znaczenie dla prowadzonej konsultacji lub terapii, proszę kliknąć poniżej.'
      }
    },
    child: {
      footer: 'Wyrażam zgodę na przetwarzanie danych podopiecznego przez',
      generic: {
        title1: 'Zgoda na przetwarzanie danych osobowych podopiecznego',
        title2: '@:gdpr.gdprPages.adult.generic.title2',
        p11: 'W związku z tym, że podczas terapii lub konsultacji będą zbierane informacje o szczególnym, prywatnym charakterze, proszę o wyrażenie zgody na przetwarzanie danych osobowych Pana/Pani podopiecznego',
        p12: 'Niewyrażenie zgody uniemożliwi prawidłowe przeprowadzenie terapii lub konsultacji. Wyrażona zgoda może być w każdej chwili cofnięta, jednak cofnięcie zgody nie wpłynie na zgodność z prawem przetwarzania, które miało miejsce przed jej wycofaniem.',
        p211: 'Administratorem danych osobowych podopiecznego',
        p212: 'jest',
        p22: ', u którego dostępne są szczegółowe informacje na temat przetwarzania danych osobowych.',
        p31: 'Jeżeli zgadza się Pan/Pani na przetwarzanie przez Administratora danych osobowych niezbędnych do przeprowadzenia terapii lub konsultacji, w tym danych dotyczących stanu zdrowia i innych okoliczności dotyczących życia prywatnego Pana/Pani podopiecznego',
        p32: 'mających znaczenie dla prowadzonej terapii lub konsultacji, proszę kliknąć poniżej.'
      },
      psychotherapist: {
        title1: '@:gdpr.gdprPages.child.generic.title1',
        title2: '@:gdpr.gdprPages.adult.psychotherapist.title2',
        p11: 'W związku z tym, że podczas psychoterapii lub konsultacji psychologicznej będą zbierane informacje o szczególnym, prywatnym charakterze, proszę o wyrażenie zgody na przetwarzanie danych osobowych Pana/Pani podopiecznego',
        p12: 'Niewyrażenie zgody uniemożliwi prawidłowe przeprowadzenie terapii lub konsultacji. Wyrażona zgoda może być w każdej chwili cofnięta, jednak cofnięcie zgody nie wpłynie na zgodność z prawem przetwarzania, które miało miejsce przed jej wycofaniem.',
        p211: '@:gdpr.gdprPages.child.generic.p211',
        p212: '@:gdpr.gdprPages.child.generic.p212',
        p22: '@:gdpr.gdprPages.child.generic.p22',
        p31: 'Jeżeli zgadza się Pan/Pani na przetwarzanie przez Administratora danych osobowych niezbędnych do przeprowadzenia terapii, w tym danych dotyczących stanu zdrowia, życia rodzinnego i innych okoliczności dotyczących życia prywatnego Pana/Pani podopiecznego',
        p32: 'mających znaczenie dla prowadzonej terapii lub konsultacji, proszę kliknąć poniżej.'
      },
      speech: {
        title1: '@:gdpr.gdprPages.child.generic.title1',
        title2: '@:gdpr.gdprPages.adult.speech.title2',
        p11: 'W związku z tym, że podczas terapii lub konsultacji logopedycznej będą zbierane informacje o szczególnym, prywatnym charakterze, proszę o wyrażenie zgody na przetwarzanie danych osobowych Pani/Pana podopiecznego',
        p12: 'Niewyrażenie zgody uniemożliwi prawidłowe przeprowadzenie terapii lub konsultacji. Wyrażona zgoda może być w każdej chwili cofnięta, jednak cofnięcie zgody nie wpłynie na zgodność z prawem przetwarzania, które miało miejsce przed jej wycofaniem.',
        p211: '@:gdpr.gdprPages.child.generic.p211',
        p212: '@:gdpr.gdprPages.child.generic.p212',
        p22: '@:gdpr.gdprPages.child.generic.p22',
        p31: 'Jeżeli zgadza się Pan/Pani na przetwarzanie przez Administratora danych osobowych niezbędnych do przeprowadzenia terapii lub konsultacji logopedycznej, w tym danych dotyczących stanu zdrowia, życia rodzinnego, nagrań mowy, zdjęć (np. twarzy, jamy ustnej, zgryzu etc.) i innych okoliczności dotyczących życia prywatnego podopiecznego',
        p32: 'mających znaczenie dla prowadzonej konsultacji lub terapii, proszę kliknąć poniżej.'
      }
    }
  }
}
