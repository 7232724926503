module.exports = {
  day: 'Day',
  month: 'Month',
  week: 'Week',
  today: 'Today',
  newMeeting: 'New meeting',
  defaultView: 'Default view',
  availabilityView: 'Availability view',
  roomView: 'Room view',
  therapistView: 'Therapist view',
  serviceView: 'Service view',
  roomTimelineView: 'Room Timeline view',
  therapistTimelineView: 'Therapist Timeline view',
  removeSingleMeeting: 'Remove single',
  removeSeriesOfMeetings: 'Remove series',
  removeP24EventWarn:
    'This event has at least one Przelewy24 transaction. Before proceeding, please check status of these transactions. If you wish to refund money to the patient, before deleting this event click on the refund button and wait for confirmation',
  show: 'Show',
  addNote: 'Add note',
  noNoteHasBeenAdded: 'No note has been added',
  noPatientNoteHasBeenAdded: 'No patient note has been added',
  noSecretNoteHasBeenAdded: 'No secret note has been added',
  secretNoteNotAvailable: 'The secret note is not available for you',
  meetingIsPartOfSeries: 'This meeting is part of a meeting series',
  returnPayment: 'Return payment',
  sureToCreateRefund:
    'Do you really want to create this refund? Any remaining funds from this transaction will be returned to the customers',
  przelewy24Status: {
    paidOnline: 'Paid online',
    awaitForVerify: 'Waiting for transaction confirmation',
    awaitForRefund: 'Waiting for transaction refund confirmation',
    refunded: 'Payment has been refunded',
    unpaidOnline: 'Transaction is unpaid',
    otherStatus: 'Unknown status',
    partiallyRefunded: 'Refunded {amountReturned}/{totalAmount} zł'
  },
  refunded: 'Refunded: ',
  otherStatus: 'Status unknown',
  meetingDetails: {
    title: 'Meeting details',
    showPatientData: 'Show patient data',
    locked:
      'This meeting has already been change (out of sync with the meeting series)',
    shifted:
      'This meeting has already been shifted by the "Shift the day of the series" operation (out of sync with the meeting series)',
    archived:
      'This meeting has at least one patient that was deleted or the couple was deleted',
    archivedCouple: 'Archived couple'
  },
  payment: 'Payment',
  doYouWantToRemoveThisMeeting: 'Do you want to remove this meeting?',
  doYouWantToRemoveWholeSeries:
    'Do you want to remove this meeting or the whole series? ',
  changeMeetingToSeries: 'Turn the meeting to a meeting series',
  synchronizeWithP24: 'Synchronize with P24',
  form: {
    newMeeting: 'New meeting',
    meetingType: 'Meeting type',
    meetingWithPatient: 'Single patient meeting',
    groupMeeting: 'Group therapy meeting',
    pairMeeting: 'Couple therapy meeting',
    meetingWithoutPatient: 'Meeting without patients',
    time: 'Time',
    duration: 'Duration',
    date: 'Date',
    selectPatient: 'Select patient',
    selectService: 'Select service',
    service: 'Service',
    selectGroup: 'Select group',
    selectPair: 'Select pair',
    selectRoom: 'Select room',
    pair: 'Pair | Pairs',
    room: 'Room',
    patient: 'Patient',
    selectTherapist: 'Select therapist | Select therapists',
    therapist: 'Therapist | Therapists',
    therapists: 'Therapist/Therapists',
    searchPatient: 'Search for a patient',
    searchService: 'Search for a service',
    searchCouple: 'Search for a couple',
    searchGroup: 'Search for a group',
    repeatingMeeting: 'This meeting repeats',
    sendSms: 'Send SMS after scheduling meeting',
    addService: '@:services.addService',
    addPatient: '@:patients.addPatient',
    addCouple: '@:patients.couples.addCouple',
    addGroup: '@:groups.addGroup',
    meetingName: 'Meeting name',
    presence: 'Presence',
    payment: 'Payment',
    patientPaid: 'Patient paid',
    paymentMethod: 'Payment method',
    paymentDate: 'Payment date',
    note: 'Note',
    secretNote: 'Secret note',
    patientNote: 'Patient note',
    editNote: 'Edit note',
    saveNote: 'Save note'
  },
  tooltips: {
    clickToSeeCoupleDetails: 'Click to see couple details',
    clickToSeeDetails: 'Click to see patient details',
    clickToSendSMSFromModal: 'Click to send SMS message',
    clickToRemoveMeeting: 'Click to remove meeting',
    clickToSeeSessionDetails: 'Click to see session details',
    clickToSeeSessionDetailsDisabled:
      'Session details visible only to the attending therapist',
    noteIsBeingEdited: 'Note has not been saved',
    snapDuration: 'Adjust timeline granularity\nfor dragged meetings',
    notAvailableRoom: 'This room is taken in this time by another therapist',
    notCompatibleRoom: 'Add meeting to room excluded from service'
  },
  editDialog: {
    // titles
    editMeeting: 'Edit meeting',
    editSeriesOfMeetings: 'Edit meeting series',
    editForceSeriesOfMeetings: 'Overwrite meeting series',
    editForceSeriesOfMeetingsConfirm: 'I confirm',
    shiftMeeting: 'Shift meeting',
    shiftSeriesOfMeetings: 'Shift the day of the series',
    // descriptions
    editMeetingDesc: `Change only this meeting (individual change).

    The meeting will not be changed by editing or shifting the meeting series in the future.`,
    editSeriesOfMeetingsDesc: `Change this and all future meetings in this meeting series - except the meetings that have been changed individually.

    Meetings shifted with the option to shift a series of meetings are not subject to change.`,
    editForceSeriesOfMeetingsDesc: `Change this and all future meetings in this meeting series - including meetings changed individually.

    Meetings shifted with the option to shift a series of meetings are not subject to change.`,
    shiftMeetingDesc:
      'This meeting will not be changed in the future by changing the meeting series nor shifting the series (individual change)',
    shiftSeriesOfMeetingsDesc: `This meeting and all future meetings in this series on that day of the week will be shifted - except for meetings that have been changed individually.

    It will not be possible to edit shifted meetings by overwriting the series of meetings`,
    warningDesc:
      'This meeting and all future meetings in this series will be replaced, including meetings changed individually'
  },
  refundDialog: {
    header: 'Amount to refund (max. {amount}zł)',
    partialRefund: 'Refund partially',
    button: 'Return funds',
    error: 'Refund is invalid!'
  }
}
