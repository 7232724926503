module.exports = {
  searchForAvailableDeadline: 'Znajdź wolny termin na spotkanie',
  availableSlotList: 'Lista dostępnych terminów',
  refresh: 'Odśwież wyniki',
  remainingSlots: 'Pozostałe terminy:',
  noBreak: 'Brak',

  // inputs
  choose: {
    specialist: 'Wybierz specjalistę/-ów',
    service: 'Wybierz usługę',
    dateRange: 'Wybierz zakres terminów',
    meetingParameters: 'Wybierz parametry spotkania',
    searchParameters: 'Parametry wyszukiwania',
    meetingBreak: 'Przerwa po spotkaniu',
    showCanceledMeetingsAsFreeSlot: 'Odwołane spotkania jako wolny termin',
    showAvailableSlotsEvery: 'Wolny termin co',
    showOnlyTherapistsAvailableInRegistration:
      'Tylko osoby dostępne w rej. online',
    exactSlot: 'Proponuj nowe spotkania co'
  },
  placeholders: {
    chooseSpecialist: 'Wybierz specjalistę',
    chooseService: 'Wybierz usługę'
  },

  // list
  list: {
    therapist: 'Terapeuta',
    date: 'Data',
    time: 'Czas'
  },

  actions: {
    choose: 'Wybierz',
    next: 'Pokaż kolejne wyniki',
    show: 'Pokaż',
    openMeeting: 'Otwórz okno z tworzeniem nowego spotkania'
  },

  table: {
    therapist: 'Terapeuta',
    date: 'Data',
    time: 'Czas',
    service: 'Usługa',
    empty:
      'Zmień lub wybierz przynajmniej jednego specjalistę lub usługę, aby zobaczyć wyniki.'
  }
}
