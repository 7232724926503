module.exports = {
  choosePatients: '@:patients.selectPatient',
  addSelectedPatients: 'Add Selected Patients',
  editCoupleMembers: 'Edit couple members',
  popups: {
    editName: 'Edit couple name',
    coupleName: 'Couple name',
    warningCaption1: 'At least one patient has to remain in a couple!',
    warningCaption2: 'Add a new member to remove/ change this one.',
    removeFromCouple: 'Remove from Couple',
    deletePatient: 'Delete patient',
    deletePatientTitle: 'Delete patient ?',
    deletePatientConfirmation:
      'Are you sure you want to delete this patient? All patient history will be removed.',
    deletePatientWarning: 'This cannot be reversed!',
    removePatientTitle: 'Remove patient from couple',
    removePatientConfirmation:
      'Are you sure you want to remove that patient from this couple?'
  },
  menu: {
    editName: 'Edit name',
    finishTherapy: 'Finish therapy',
    reactivateTherapy: 'Reactivate therapy',
    deleteCouple: 'Delete couple',
    confirmDelete: 'Do you want to delete this couple?',
    confirmDeletePatient: 'Do you want to delete this patient?',
    finishTherapyDialog:
      'Are you sure you want to finish the active therapy for this couple?'
  },
  tabs: {
    meetingHistory: 'Meeting history',
    groupMembers: 'Group members',
    attachments: 'Attachments',
    history: 'History',
    invoices: 'Invoices'
  },
  finishTherapyDialog: {
    title: 'Finish therapy',
    caption1:
      'Are you sure you want to finish the active therapy for this couple?',
    caption2:
      'All data will be saved, all future meetings from your calendar will be deleted (starting from tomorrow). Are you sure?',
    caption2InMeeting:
      'All data will be saved, all future meetings from your calendar will be deleted (after this meeting). Are you sure?'
  },
  reactivateTherapyDialog: {
    title: 'Reactivate therapy',
    caption1:
      'Are you sure you want to reactivate the therapy for this couple?',
    caption2:
      'Couple will be again available for meetings and will appear in the couples list. Are you sure?'
  }
}
