const {
  getEmptyTableMessage,
  getEmptyTableOnSearchMessage
} = require('./utils')

module.exports = {
  addMember: 'Add group member',
  membershipAddedOn: 'Added on',
  membershipEndingOn: 'Ending on',
  searchPlaceholder: 'Search for patient',
  confirmDelete: 'Do you want to delete this group member?',
  table: {
    empty: getEmptyTableMessage('group members', 'Add group member'),
    emptyOnSearch: getEmptyTableOnSearchMessage('group members')
  }
}
