module.exports = {
  title: 'Working time',
  availability: {
    edit: 'Edit availability',
    exceptions: {
      title: 'Exceptions in Work Schedule',
      description:
        'Add dates when your availability differs from regular schedules'
    },
    unavailable: 'Unavailable',
    tooltip: {
      add: 'Add working hours',
      delete: 'Remove working hours',
      copy: 'Copy working hours to next day'
    },
    sureRejectChanges: 'Are you sure you want to reject changes?',
    workTimeSaved: 'Work time preset succesfully updated'
  },
  holidays: {
    statusTooltip: 'Accepted by {by} on {on}',
    awaitingApproval: 'Awaiting approval',
    dialog: {
      title: 'Add Leave',
      selectDateOrRange: 'Select Date or Range of Leave',
      note: 'Note for Request',
      notePlaceholder: 'Here you can add a comment to the request',
      infoContent:
        'Attention - adding leave will cancel scheduled appointments during that time. If you have SMS notifications enabled, patients will be notified according to the set notice period.',
      selectType: 'Select Type of Leave'
    },
    type: {
      restLeave: 'Vacation Leave',
      sickLeave: 'Sick Leave',
      other: 'Other'
    }
  }
}
