const {
  getEmptyTableMessage,
  getEmptyTableOnSearchMessage
} = require('./utils')

module.exports = {
  patient: 'Patient | Patients',
  addPatient: 'Add patient',
  patientData: 'Patient data',
  invoiceData: 'Invoice data',
  basicInfo: 'Basic info',
  assignedTo: '@:groups.assignedTo',
  typeTherapistToSelect: 'Type therapist to select',
  createPatient: 'Create patient',
  addPatientData: "Add patient's data",
  addPatientAddress: "Add patient's address",
  selectPatient: 'Select patient | Select patients',
  searchPlaceholder: 'Patient name or surname',
  patientInvoiceDataUpdated: 'Patient invoice data updated',
  table: {
    empty: getEmptyTableMessage('patients', 'Add Patients'),
    emptyOnSearch: getEmptyTableOnSearchMessage('patients'),
    tabs: {
      active: '@:common.active',
      inactive: '@:common.inactive',
      all: 'All',
      couples: 'Couples',
      groups: 'Groups',
      assignedTo: '@:common.assignedTo',
      unassigned: 'Unassigned'
    }
  },
  couples: {
    addCouple: 'Add couple',
    coupleName: 'Couple name',
    assignedTo: 'Couple assigned to',
    typePatientNameToSelect: "Type patient's name to select",
    typeTherapistNameToSelect: "Type therapist's name to select",
    phoneNumber: '@:common.phoneNumber',
    patients: 'Patients',
    searchPlaceholder: 'Search for couple',
    finishedAt: 'Finished at',
    showDeactivatedCouples: 'Show also couples that finished therapy',
    table: {
      empty: getEmptyTableMessage('couples', 'Add couple'),
      emptyOnSearch: getEmptyTableOnSearchMessage('couples')
    }
  },
  details: {
    history: '@:groups.cards.history',
    contactDetails: '@:groups.cards.contactDetails',
    groupMembers: '@:groups.cards.groupMembers',
    upcomingMeetings: '@:groups.cards.upcomingMeetings',
    attachments: '@:groups.cards.attachments',
    gdpr: '@:groups.cards.gdpr',
    notifications: '@:groups.cards.notifications'
  },
  guardians: {
    guardian: 'Guardian | Guardians',
    patientGuardians: "Patient's guardians",
    addGuardian: 'Add new guardian',
    table: {
      empty: getEmptyTableMessage('guardians', 'Add new guardian'),
      emptyOnSearch: getEmptyTableOnSearchMessage('guardians')
    },
    deleteGuardian: 'Do you want to delete guardian {name}?'
  },
  tabs: {
    meetingHistory: '@:groups.tabs.meetingHistory',
    psychotherapy: 'Psychotherapy',
    speechTherapy: 'Speech-therapy',
    si: 'SI therapy',
    educator: 'Educator',
    patientData: 'Patient data',
    attachments: 'Attachments',
    gdpr: 'GDPR',
    gdprDoc: 'GDPR documents',
    invoices: 'Invoices & payments'
  },
  menu: {
    finishTherapy: '@:groups.menu.finishTherapy',
    reactivateTherapy: 'Reactivate therapy',
    therapyIsFinished: 'Therapy is finished',
    history: '@:groups.menu.history',
    delete: '@:common.delete',
    confirmDelete: 'Do you want to delete this patient?'
  },
  forms: {
    psychotherapy: {
      title: 'Psychotherapy notes',
      occupation: 'Current occupation',
      medicalHistory: 'Medical history',
      diagnosis: 'Diagnosis',
      triggerForTherapy: 'Trigger for therapy',
      expectations: 'Therapy expectations',
      otherNotes: 'Other notes'
    },
    speechTherapy: {
      title: 'Speech-therapy notes',
      generalInfo: 'General information',
      generalAim: 'General aim',
      medicalHistory: '@:patients.forms.psychotherapy.medicalHistory',
      speechExamination: 'Speech examination',
      articulationExamination: 'Articulation examination',
      diagnosisTherapyPlan: 'Diagnosis and therapy plan',
      otherNotes: '@:patients.forms.psychotherapy.otherNotes'
    },
    si: {
      title: 'SI notes',
      generalInfo: 'General information',
      siDiagnosis: 'SI diagnosis',
      therapyHints: 'SI therapy hints',
      sensoryDiet: 'Sensory diet',
      generalAim: 'General aim',
      medicalHistory: '@:patients.forms.psychotherapy.medicalHistory',
      diagnosisTherapyPlan: 'Diagnosis and therapy plan',
      otherNotes: '@:patients.forms.psychotherapy.otherNotes'
    },
    educator: {
      title: 'Educator notes',
      generalInfo: 'General information',
      generalAim: 'General aim',
      medicalHistory: '@:patients.forms.psychotherapy.medicalHistory',
      diagnosisTherapyPlan: 'Diagnosis and therapy plan',
      otherNotes: '@:patients.forms.psychotherapy.otherNotes'
    }
  },
  finishTherapyDialog: {
    title: 'Finish therapy',
    caption1:
      'Are you sure you want to finish the active therapy for this patient?',
    caption2:
      'All data will be saved, all future meetings from your calendar will be deleted (starting from tomorrow). Are you sure?',
    caption2InMeeting:
      'All data will be saved, all future meetings from your calendar will be deleted (after this meeting). Are you sure?'
  },
  reactivateTherapyDialog: {
    title: 'Reactivate therapy',
    caption1:
      'Are you sure you want to reactivate the therapy for this patient?',
    caption2:
      'Patient will be again available for meetings and will appear in the active patients list. Are you sure?'
  },
  crumbs: {
    patients: 'Patients'
  },
  actions: {
    send: 'Send GDPR consent'
  }
}
