const {
  getEmptyTableMessage,
  getEmptyTableOnSearchMessage
} = require('./utils')

module.exports = {
  employees: 'Employees',
  addEmployee: 'Add Employee',
  acronym: 'Acronym',
  details: '@:common.details',
  roles: '@:common.roles',
  color: '@:common.color',
  onlineReg: 'Online reg.',
  fullName: '@:common.fullName',
  name: '@:common.firstName',
  email: '@:common.email',
  surname: '@:common.surname',
  phoneNumber: '@:common.phoneNumber',
  practiceBranch: 'Practice branch',
  preferredRoom: 'Preferred room',
  selectPracticeBranch: 'Select branch',
  all: 'All',
  searchPlaceholder: 'Type to search',
  employeeData: 'Employee data',
  availabilitySettings: 'Availability settings',
  shortDescription: 'Short description',
  shortDescriptionPlaceholder:
    'Type e.g "psychologist psychotherapist", "neurologist" etc.',
  invitationTitle:
    "You've been invited to join <span class='text-primary'>{practiceName}</span>",
  invitationEmailWarning: `
    Invitation was sent to <b>{invitedEmail}</b> and you're logged in
    with <b>{actualEmail}</b
    >. If that wasn't intentional, please click
    <b>"Decline and Logout"</b> button and login with the correct
    account.
  `,
  backToEmployeesList: 'Back to employees list',
  acceptInvitation: 'Accept invitation',
  declineInvitation: 'Decline and Logout',
  rolesEnum: {
    adm: 'Admin | Admins',
    rec: 'Reception',
    emp: 'Employee | Employees',
    tech: 'Technical staff',
    fin: 'Financial'
  },
  editAvailability: 'Edit availability',
  table: {
    empty: getEmptyTableMessage('employees', 'Add Employee'),
    emptyOnSearch: getEmptyTableOnSearchMessage('employees')
  },
  tooltips: {
    clickAvailability: 'Click to set availability',
    editEmployee: 'Edit employee',
    resendInvitation: 'Resend Invitation',
    accountConfirmed: 'Account has been confirmed',
    clickToRemoveEmployee: 'Click to remove employee'
  },
  form: {
    selectPreferredRoom: 'Select preferred room',
    selectPracticeBranchFirst: 'Select practice branch first',
    selectRoles: 'Select roles',
    confirmDeleteEmployee: 'Do you want to delete this employee?',
    personalInformationTitle: 'Basic personal information',
    personalInformationDescription:
      'Basic personal information, including first name, last name, phone number, and email address.',
    professionalInformationTitle: 'Professional information',
    onlinePersonalizationTitle: 'Profile picture and signature',
    onlinePersonalizationDescription:
      'Visible in calendar and online registration profile picture and short description',
    professionalInformationDescription:
      "Professional information, including the person's role within the organization, their department, and room preferences.",
    onlineRegistrationTitle: 'Employee Access',
    onlineRegistrationDescription:
      "Define the employee's role and preferred room.",
    additionalActionsTitle: 'Additional information and actions',
    additionalActionsDescription:
      'Additional information, such as color and acronym (likely for internal identification), and action buttons, including deleting the person or saving changes.'
  },
  availableInOnlineRegistration: {
    title: 'This person is available for online booking',
    description:
      "If you don't check this, patients won't be able to schedule appointments with this person"
  },
  clickToDeleteThisPerson: {
    title: 'Click to delete this person',
    description:
      'The deleted person will no longer have access to your data and your center'
  },
  holidays: {
    statusTooltip: 'Accepted by {by} on {on}',
    awaitingApproval: 'Awaiting approval',
    dialog: {
      title: 'Add Leave',
      selectDateOrRange: 'Select Date or Range of Leave',
      note: 'Note for Request',
      notePlaceholder: 'Here you can add a comment to the request',
      infoContent:
        'Attention - adding leave will cancel scheduled appointments during that time. If you have SMS notifications enabled, patients will be notified according to the set notice period.',
      selectType: 'Select Type of Leave'
    },
    type: {
      restLeave: 'Vacation Leave',
      sickLeave: 'Sick Leave',
      other: 'Other'
    }
  }
}
