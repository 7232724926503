module.exports = {
  contactDetails: {
    title: 'Dane kontaktowe',
    empty: 'Brak danych kontaktowych',
    add: 'Dodaj'
  },
  groupMembers: {
    title: 'Członkowie grupy',
    empty: 'Brak członków grupy',
    add: 'Dodaj uczestnika'
  },
  upcomingMeetings: {
    title: 'Nadchodzące spotkania',
    empty: 'Brak nadchodzących spotkań',
    add: 'Umów spotkanie'
  },
  coupleMembers: {
    title: 'Członkowie pary',
    empty: 'Brak członków pary'
  },
  coupleUpcomingMeeting: {
    title: 'Nadchodzące spotkania',
    empty: 'Brak nadchodzących spotkań'
  },
  coupleAttachments: {
    title: 'Załączniki'
  },
  therapist: {
    title: 'Terapeuta'
  },
  history: {
    title: 'Historia',
    empty: 'Brak historii pacjenta',
    therapist: 'Terapeuta',
    added: 'W bazie od:'
  },
  attachments: {
    title: 'Załączniki',
    empty: 'Brak załączników',
    add: 'Dodaj załącznik'
  },
  gdpr: {
    title: 'RODO',
    empty: 'Brak plików RODO',
    add: 'Dodaj nowy'
  },
  notifications: {
    title: 'Powiadomienia',
    true: 'Pacjent będzie otrzymywał automatyczne powiadomienia',
    false: 'Automatyczne powiadomienia są wyłączone'
  },
  groupNotifications: {
    title: 'Powiadomienia',
    true: 'Grupa będzie otrzymywała automatyczne powiadomienia',
    false: 'Automatyczne powiadomienia są wyłączone'
  },
  invoices: {
    title: 'Faktury i płatności',
    empty: 'Brak faktur',
    add: 'Dodaj fakturę'
  }
}
