module.exports = {
  age: 'Wiek:',
  isSince: 'W terapii od:',
  meetingsHeld: 'Zakończone spotkania:',
  therapist: 'Terapeuta',
  therapists: 'Terapeuci',
  searchTherapists: 'Wyszukaj terapeutę…',
  patientReceiveNotify: 'Pacjent otrzyma automatyczne powiadomienia',
  patientNotReceiveNotify: 'Pacjent NIE otrzyma automatycznych powiadomień',
  coupleReceiveNotify: 'Para otrzyma automatyczne powiadomienia',
  coupleNotReceiveNotify: 'Para NIE otrzyma automatycznych powiadomień',
  therapyFinishedOn: 'Terapia zakończona w dniu:',
  patientNoPhoneNumber:
    'Aby włączyć automatyczne powiadomienia sms uzupełnij numer telefonu pacjenta',
  coupleNoPhoneNumber:
    'Aby włączyć automatyczne powiadomienia sms uzupełnij numer telefonu pacjentów pary',
  smsAll: 'SMS do wszystkich członków',
  emailAll: '@ Wyślij e-mail do wszystkich członków',
  reactivateTherapy: 'Ponownie rozpocznij terapię',
  issueInvoice: 'Wystaw fakturę',
  scheduleMeeting: 'Umów spotkanie',
  newInvoice: '@:invoices.newInvoice',
  therapyFinished:
    'Terapia została zakończona! Rozpocznij ją na nowo, aby zaplanować nowe spotkania.',
  prev: 'Poprzednia',
  next: 'Następna',
  belongsTo: 'Należy do:',
  year: '(l)',
  month: '(m)',
  week: '(t)',
  description: 'Opis:',
  changeDescription: 'Zmień',
  saveDescription: 'Zapisz',
  cancelDescription: 'Anuluj'
}
