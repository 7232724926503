module.exports = {
  chooseTheRightType: 'Select the right business type',
  businessType: 'Business type',
  invitationLinkEmpty: 'The link is unavailable',

  // divider
  companyName: 'Company name',
  fullName: 'Full name',

  // divider
  contactPersonEmail: 'Contact email',
  invoiceEmail: 'Invoice email',
  pesel: 'PESEL (Personal Identification Number)',
  nip: 'NIP (Tax Identification Number)',

  // divider
  bankAccount: 'Bank account number',

  // divider
  servicesDescription: 'Services description',

  // divider
  contactPersonName: 'Representative',
  contactAddress: 'Representative email',
  contactPersonPhone: 'Representative phone number',
  technicalPersonName: 'Technical person',
  technicalPersonEmail: 'Technical person email',
  technicalPersonPhone: 'Technical person phone number',
  addressStreet: 'Street and house number',
  companyAddress: 'Company street and house number',
  addressPostCode: 'Postal code',
  contactPostalCode: 'Contact postal code',
  companyPostalCode: 'Company postal code',
  addressCity: 'City',
  contactCity: 'Contact city',
  companyCity: 'Company city',
  phoneNumber: 'Phone number',
  countryName: 'Country',
  country: 'Country',
  country2: 'Country',
  typePhoneNumber: 'Phone number type',
  Poland: 'Poland',

  typeBasicData: "Enter your company's basic data",
  representativeData: "Authorized person's data for contract conclusion",
  techData: 'Technical contact data (if no dedicated person is available)',
  mainAddress: "Enter your company's main address",
  correspondenceAddressData: 'Enter correspondence address',
  phoneNumberErrorMessage: 'Type the right phone number',
  topWarning:
    'If you want to start payments for patients via online registration, you must register in the Przelewy24 system. Complete the form below and we will send your data to Przelewy24. You will learn about the next steps in the next messages from Przelewy24. Once the procedure is complete, we will receive a message from P24 and you will be able to accept payments in your office. The entire procedure usually takes about 2 business days.',
  bottomInfo:
    'After providing your data, we will send it to the Przelewy24 store. Your Przelewy24 representative will contact you with information that you can complete the procedure and sign for access. After signing the contract, Przelewy24 will send us information and we could implement payments for you.',
  saveButton: 'Send to Przelewy24',
  serviceAvailability: 'Service available only in Poland',
  isDataCorrect: 'Is data correct?',
  townName: 'City',
  correspondenceAddress: 'Street and house number',
  correspondenceZipCode: 'Post code',
  correspondenceCountry: 'Country',
  correspondenceTownName: 'City',
  NIP: 'NIP',
  REGON: 'REGON',
  KRS: 'KRS',
  regon: 'REGON',
  krs: 'KRS',
  representativePesel: 'Representative PESEL',
  addressCountry: 'Country',
  modalSaveButton: 'The data is correct, send to Przelewy24',
  cancelAndCorrect: 'Cancel and correct',
  formCongrats:
    'Thank you for completing the form. Your data has been sent to Przelewy24. To complete your registration, please visit the link below and complete your registration.',

  // left side
  headers: {
    basicData: 'Company Data',
    representativeData: 'Representative',
    technicalData: 'Technical Contact',
    address: 'Address',
    correspondenceAddressData: 'Correspondence Address'
  },

  identificators: {
    PESELNumber: 'PESEL',
    NIPNumber: 'NIP',
    REGONNumber: 'REGON',
    KRSNumber: 'KRS'
  },

  activityTypeOptions: {
    naturalPerson: 'Natural Person',
    soleProprietoship: 'Sole Proprietorship',
    civilPartnership: 'Civil Partnership',
    generalPartnership: 'General Partnership',
    limitedPartnership: 'Limited Partnership',
    limitedJointStockPartnership: 'Limited Joint Stock Partnership',
    jointStockCompany: 'Joint Stock Company',
    limitedLiabilityCompany: 'Limited Liability Company',
    associationFoundationEtc:
      'Association, Foundation, Public Benefit Organization',
    cooperative: 'Cooperative'
  }
}
