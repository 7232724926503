module.exports = {
  COUPLE_CREATED: 'Para została utworzona',
  COUPLE_THERAPIST_ASSIGNED: 'Terapeuta został przypisany do pary',
  COUPLE_ACTIVATED: 'Para została aktywowana',
  COUPLE_DEACTIVATED: 'Para została zdezaktywowana',
  COUPLE_NOTES_ADDED: 'Notatki ze spotkania zostały dodane',
  COUPLE_NOTES_VIEWED: 'Obejrzano notatki ze spotkania',
  COUPLE_THERAPY_FINISHED: 'Terapia pary została zakończona',
  COUPLE_THERAPY_REACTIVATED: 'Terapia pary została reaktywowana',
  COUPLE_NOTES_PRINTED: 'Notatki pary zostały wydrukowane',

  GROUP_CREATED: 'Grupa została utworzona',
  GROUP_THERAPIST_ASSIGNED: 'Terapeuta został przypisany do grupy',
  GROUP_ACTIVATED: 'Grupa została aktywowana',
  GROUP_DEACTIVATED: 'Grupa została zdezaktywowana',
  GROUP_NOTES_ADDED: 'Notatki ze spotkania zostały dodane',
  GROUP_NOTES_VIEWED: 'Obejrzano notatki ze spotkania',
  GROUP_NOTES_PRINTED: 'Notatki grupy zostały wydrukowane',

  PATIENT_CREATED: 'Pacjent został utworzony',
  PATIENT_ACTIVATED: 'Pacjent został aktywowany',
  PATIENT_DEACTIVATED: 'Pacjent został zdezaktywowany',
  PATIENT_THERAPIST_ASSIGNED: 'Terapeuta został przypisany do pacjenta',
  PATIENT_ASSIGNED_TO_GROUP: 'Pacjent został przypisany do grupy',
  PATIENT_ASSIGNED_TO_COUPLE: 'Pacjent został przypisany do pary',
  PATIENT_CHANGED_STATUS: 'Status spotkania został zmieniony',
  PATIENT_NOTES_ADDED: 'Notatki ze spotkania zostały dodane',
  PATIENT_NOTES_EDITED: 'Notatki ze spotkania zostały zredagowane',
  PATIENT_NOTES_VIEWED: 'Obejrzano notatki ze spotkania',
  PATIENT_NOTES_PRINTED: 'Notatki ze spotkania zostały wydrukowane',
  PATIENT_PSYCHOTHERAPY_NOTES_ADDED: 'Dodano notatki psychoterapii',
  PATIENT_SPEECH_THERAPY_NOTES_ADDED: 'Dodano notatki logopedyczne',
  PATIENT_PSYCHOTHERAPY_NOTES_EDITED:
    'Notatki psychoterapii zostały zredagowane',
  PATIENT_SPEECH_THERAPY_NOTES_EDITED:
    'Notatki logopedyczne zostały zredagowane',
  PATIENT_PSYCHOTHERAPY_NOTES_VIEWED: 'Przeglądano notatki z psychoterapii',
  PATIENT_SPEECH_THERAPY_NOTES_VIEWED:
    'przeglądano notatki z terapii logopedycznej',
  PATIENT_PSYCHOTHERAPY_NOTES_PRINTED:
    'Notatki psychoterapii zostały wydrukowane',
  PATIENT_SPEECH_THERAPY_NOTES_PRINTED:
    'Notatki logopedyczne zostały wydrukowane',
  PATIENT_THERAPY_FINISHED: 'Terapia pacjenta została zakończona',
  PATIENT_THERAPY_REACTIVATED: 'Terapia pacjenta została reaktywowana',

  date: 'Data',
  action: 'Akcja',
  person: 'Osoba',
  therapist: 'Terapeuta',

  tooltips: {
    download: 'Naciśnij, aby filtrować notatki',
    filter: 'Click to filter notes'
  },
  table: {
    empty: 'W wybranym okresie nie wykonano żadnych akcji'
  }
}
