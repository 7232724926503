const actions = require('./actions')
const common = require('./common')
const employees = require('./employees')
const groups = require('./groups')
const groupMembers = require('./groupMembers')
const patients = require('./patients')
const practice = require('./practice')
const rooms = require('./rooms')
const services = require('./services')
const sessions = require('./sessions')
const breadcrumb = require('./breadcrumb')
const meetingHistoryTable = require('./meetingHistoryTable')
const detailCards = require('./detailCards')
const toasts = require('./toasts')
const print = require('./print')
const couples = require('./couples')
const reports = require('./reports')
const income = require('./income')
const tooltip = require('./tooltip')
const placeholders = require('./placeholders')
const detailData = require('./detailData')
const invoices = require('./invoices')
const calendar = require('./calendar')
const gdpr = require('./gdpr')
const sms = require('./sms')
const registration = require('./registration')
const registrationP24 = require('./registrationP24')
const worktime = require('./worktime')
const registrationPayments = require('./registrationPayments')
const registrationPrzelewy24Config = require('./registrationPrzelewy24Config')
const availableSlotsSearch = require('./availableSlotsSearch')
const reservations = require('./reservations')
const announcements = require('./announcements')

module.exports = {
  couples,
  common,
  practice,
  employees,
  groups,
  groupMembers,
  patients,
  availableSlotsSearch,
  services,
  rooms,
  sessions,
  breadcrumb,
  actions,
  meetingHistoryTable,
  detailCards,
  toasts,
  print,
  reports,
  income,
  tooltip,
  placeholders,
  detailData,
  invoices,
  calendar,
  gdpr,
  sms,
  registration,
  registrationP24,
  registrationPayments,
  registrationPrzelewy24Config,
  worktime,
  reservations,
  announcements,
  welcome: 'Welcome to DobryGabinet',
  table: {
    currentPageReportTemplate: 'Showing {first} to {last} of {totalRecords}'
  },
  choosePractice: {
    choosePracticeToJoin: 'Hello! Choose your practice to join:',
    practicesFor: 'Practices for',
    wantTo: 'Want to ',
    startYourOwnPractice: 'start your own practice?',
    createOwnPractice: 'Create own practice',
    notSeeingPractice: 'Not seeing your practice?',
    tryDifferentEmail: 'Try a different email',
    noPractices: 'You have no practices available to open.',
    scheduleMeeting: 'Schedule a meeting'
  },
  createPractice: {
    hello: 'Hello!',
    practiceDetails: 'Please add your practice details in order to start:',
    congratulations: 'Congratulations!',
    createdPractice: 'You have successfully created your own practice',
    summary: 'Summary',
    privacyPolicyText:
      'By selecting “Open DobryGabinet.com”, you are confirming that you have read and agree to DobryGabinet’s: ',
    privacyPolicy: 'Privacy Policy',
    statute: 'Terms and Conditions',
    termsAndConditions: 'Terms and Conditions',
    name: 'Name',
    address: 'Address',
    step: 'STEP',
    outOf: 'OUT OF',
    headerText: 'Step {step} out of {total}'
  },
  address: {
    streetName: 'Street name',
    houseNumber: 'House number',
    number: 'Number',
    apt: 'Apt. number',
    city: 'City',
    zipCode: 'Zip code',
    country: 'Country',
    county: 'County',
    selectCountry: 'Select country'
  },
  cron: {
    repeatEvery: 'Repeat every',
    repeatOn: 'Repeat on',
    ends: 'Ends',
    never: 'Never',
    after: 'After',
    onDate: 'On date',
    week: 'week | weeks',
    month: 'month | months',
    occurrence: 'occurrence | occurrences',
    weekDays: {
      mo: 'MO',
      tu: 'TU',
      we: 'WE',
      th: 'TH',
      fr: 'FR',
      sa: 'SA',
      su: 'SU',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday'
    }
  },
  sidebar: {
    calendar: 'Calendar',
    patients: 'Patients',
    practice: 'Practice',
    services: 'Services',
    employees: 'Employees',
    settings: 'Settings',
    account: 'Account',
    sms: 'SMS',
    reports: 'Reports',
    invoices: 'Invoices',
    income: 'Income',
    subscription: 'Subscription',
    gdpr: 'GDPR documents',
    onlineRegistration: 'Bookings'
  },
  user: {
    myAccount: 'My account',
    settings: 'Settings',
    signOut: 'Sign out',
    profile: 'Profile',
    hereYouCanFindInfo: 'Here you can find information related to you',
    deleteAccount: 'Delete account',
    socialSignIn: 'Social sign-in',
    followingAccountsAreConnected:
      'The following accounts are connected to your account',
    connectedAccounts: 'Connected accounts',
    clickToConnect:
      'Click on the relevant to button to connect or disconnect from the relevant service',
    deleteByClicking:
      'You can delete your account by writing an email to customer service asking us to delete your data.',
    dataWillBeDeleted:
      'All your data will be deleted. This action cannot be reversed.',
    accountCreatedOn: 'This account was created on',
    connect: 'Connect',
    disconnect: 'Disconnect',
    contact: 'Contact'
  },
  subscription: {
    subscription: 'Subscription',
    programSubscription: 'You need a subscription',
    hello: 'Welcome!',
    ourEmailAddress: 'kontakt@dobrygabinet.com',
    subscriptionExplanation:
      "If this is your first time here, you are most likely encountering DobryGabinet for the first time. We offer you 14 completely free days to test the software in your clinic or facility. It's a great opportunity to explore the program, try out different options, and ask any questions you may have. We are available at",
    subscriptionExplanation2:
      'If you need our assistance or have any questions, please contact us at:',
    subscriptionExplanation3:
      'Our team will be happy to answer your inquiries.',
    subscriptionExplanation4:
      'After the two-week free trial period, if you decide to continue using our program, you will need a monthly license. The current pricing is available on our website. If you choose the appropriate plan for yourself, please write to us at the email address provided above with information regarding the selected license, including invoice details (including VAT number)',
    subscriptionExplanation5:
      'Thank you for your interest in DobryGabinet, and we look forward to a productive collaboration!',
    additionalQuestions:
      'Do you have any additional questions? Below you will find the most frequently asked questions and answers.',
    howLongDoesTrialLast: 'How long does my trial last?',
    howLongIsTrialExplanation:
      'The trial period is 14 days. In this period of time feel free to use the application as much as you want. After this period we will ask you whether you would like to continue using it or not.',
    howToPayForSubscription: 'How do I pay for my subscription?',
    howToPayForSubscriptionAnswer:
      'To pay for the subscription, please provide us with your invoice details and the package you have chosen via email.',
    howToGetInvoice: 'How and when do I get my invoice?',
    howToGetInvoiceAnswer:
      'The invoice for the subscription will be sent to you at the beginning of the new month. The invoice has a payment period of 7 days. If your trial period ends, for example, in the middle of the month, we will issue a prorated invoice for the remaining days of that month.',
    whatHappensWhenIDontPay: 'What happens if I do not pay the invoice?',
    whatHappensWhenIDontPayAnswer:
      'If we do not receive payment for the invoice within the designated payment period, we will start charging late payment interest, which will be added to the next invoice.',
    iDontWantToContinue:
      'I do not want to continue using the program. What do I need to do?',
    iDontWantToContinueAnswer:
      'If you do not wish to continue using the system after the trial period, please send us an email with this information.',
    howToCancel: 'How can I cancel my subscription?',
    howToCancelAnswer:
      'To cancel your active subscription, we kindly ask you to send us an email with a termination request according to the terms and conditions. The notice period is 14 days.',
    needMoreTime: 'I need more time. Can I extend my trial period?',
    needMoreTimeAnswer:
      'If you need a few extra days to consider the program, please send us an email. We will gladly consider your request.',
    howToSignContract: 'How can I sign a contract with you?',
    howToSignContractAnswer:
      'Additional signing of the contract is not necessary as by creating an account, the user gives their electronic consent, which constitutes a binding agreement.',
    whatHappensToTrialDataIfIStay:
      'Will the data entered during the trial period remain in the program when I switch to a subscription?',
    whatHappensToTrialDataIfIStayAnswer:
      'Yes, all the data entered into the program during the trial period will remain in the system and will be available in the same form during the subscription period.'
  },
  validators: {
    correctEmail: 'Please enter a valid email',
    correctTime: 'Please enter a valid time',
    correctTimeRange: 'Please enter time between {start} and {end}',
    correctPhoneNumber: 'Please enter a valid phone number',
    correctTimeOrder: 'Start time has to be before end time',
    correctDuration: 'Please enter valid duration: e.g. 1:20h or 15min',
    timeRangeOverlap: 'Please enter time ranges that do not overlap',
    correctDate: 'Please enter a correct date',
    correctPrice: 'Please enter a valid price',
    correctSubdomain: 'Please enter a valid subdomain',
    isRequired: 'is required',
    tooLong: 'is too long (max:',
    breakTooLarge:
      'The break is too long! The maximum length of a break is 1 440 minutes (24 hours).',
    incorrectPesel: 'Incorrect PESEL'
  },
  toastsTitle: {
    success: 'Success',
    error: 'Error',
    warning: 'Warning',
    info: 'Info'
  },
  therapists: {
    acronym: 'ALL',
    all: 'All',
    therapists: 'therapists',
    title: 'List of therapists',
    noItems: 'No therapists found',
    searchTherapist: 'Search for therapist',
    addNew: 'Add employee',
    counselor: 'Counselor',
    addToList: 'List above calendar',
    pressMetaKeyToSelectMultiple:
      'To select multiple counselors, press Ctrl key (command on Mac)',
    favoriteTherapistsTitle: 'Choose favorite therapists'
  }
}
